import 'core-js/es/array';
import 'core-js/es/promise';
import 'core-js/es/object';
import 'core-js/es/symbol';

import React from 'react';
import './index.css';

import moment from 'moment';
import {ru} from 'moment/locale/ru';

import materialIcons from 'material-design-icons/iconfont/material-icons.css'

window.jQuery = window.$ = require('jquery');
window.moment = require('moment-timezone');

moment.updateLocale('ru', {
  longDateFormat: {
    LT: 'H:mm',
    LTS: 'H:mm:ss',
    L: 'DDD.MM.YYYY',
    LL: 'DDD.MMM.YYYY',
    LLL: 'HH:mm, DD.MMM.YYYY', // config.defaultTimeFormat,
    LLLL: 'dddd, D MMMM YYYY г., H:mm',
  },
});
moment.locale('ru');


let {configStorage} = require('./services/GraphQL');

configStorage.get()
  .then(async (config) => {
    let app = await import(/* webpackChunkName: "app" */ './App');
    if (config.sessionUser) {
      app.renderApp(config)
    } else {
      if (process.env.NODE_ENV !== 'local' && process.env.NODE_ENV !== 'development') {
        app.renderSessionExpiredPage(config)
      } else {
        app.renderLoginPage(config);
      }
    }
  }, (error) => {
    import(/* webpackChunkName: "ServiceFatalError" */ './ServiceFatalError').then((ServiceFatalError) => ServiceFatalError.default(error))
  });
