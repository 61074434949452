export const DASHBOARD_MODALDIALOG_ADD_WIDGET = 'DASHBOARD_MODALDIALOG_ADD_WIDGET';
export const DASHBOARD_SORT_WIDGETS = 'DASHBOARD_SORT_WIDGETS';
export const DASHBOARD_ADD_WIDGET = 'DASHBOARD_ADD_WIDGET';
export const DASHBOARD_REMOVE_WIDGET = 'DASHBOARD_REMOVE_WIDGET';
export const DASHBOARD_RESET_WIDGETS = 'DASHBOARD_RESET_WIDGETS';

export function showDialogAddWidget(widgetType) {
  return {
    type: DASHBOARD_MODALDIALOG_ADD_WIDGET,
    shown: true,
    widgetType,
    payload: 'ADD MODALDIALOG WIDGET',
  };
}

export function hideDialogAddWidget() {
  return {
    type: DASHBOARD_MODALDIALOG_ADD_WIDGET,
    shown: false,
    payload: 'HIDE MODALDIALOG WIDGET',
  };
}

export function sortWidgets(widgetType, reorderedWidgetIds) {
  return {
    type: DASHBOARD_SORT_WIDGETS,
    widgetType,
    reorderedWidgetIds,
  };
}

export function addWidget(widgetType, widgetId) {
  //console.log('addWidget', widgetType, widgetId);
  return {
    type: DASHBOARD_ADD_WIDGET,
    widgetType,
    widgetId,
    payload: {
      category: 'Добавлен виджет',
      label: `Добавлен : ${widgetId} - ${widgetType}`,
      action: widgetType,
      value: 1,
    },
  };
}

export function removeWidget(widgetType, widgetId) {
  return {
    type: DASHBOARD_REMOVE_WIDGET,
    widgetType,
    widgetId,
    payload: {
      category: 'Удален виджет',
      label: `Удален : ${widgetId} - ${widgetType}`,
      action: widgetType,
      value: 0,
    },
  };
}

export function resetWidgets(widgetType = 'all') {
  return {
    type: DASHBOARD_RESET_WIDGETS,
    widgetType,
    payload: 'DASHBOARD RESET ALL WIDGETS',
  };
}
