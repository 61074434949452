import gql from 'graphql-tag';

export default class AnalyticsService {
  
  constructor(client) {
    this.client = client;
  }
  
  pickOriginalError(path, error) {
    let originalError = Array.isArray(error.graphQLErrors)
      ? error.graphQLErrors.find((error) => error.path[0] === path)
      : undefined;
    return Promise.reject(originalError || error);
  }
  
  /**
   * @returns {Promise<T>}
   */
  
  exportReports(infIds, reportIds, timePeriod, sort, advancedFilter, subjects, subjectType) {
    let variables = {
      infIds, reportIds, timePeriod, sort, advancedFilter, subjects, subjectType
    };
    const mutation = gql`
      mutation export($infIds: [String], $reportIds: [String], $timePeriod: TimePeriodInput, $sort: [SortOptionsInput], 
          $advancedFilter: Json, $subjects: [String], $subjectType: String) {
        exportReports(
            infIds:         $infIds
            reportIds:      $reportIds
            timePeriod:     $timePeriod      
            sort:           $sort
            advancedFilter: $advancedFilter            
            subjects:       $subjects
            subjectType:    $subjectType
        )
      }`;
    return this.client.mutate({mutation, variables})
      .then((result) => result)
      .catch(this.pickOriginalError.bind(this, 'exportReports'));
  }
  
}