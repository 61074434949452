import gql from 'graphql-tag';

const AnnouncementFragment = `
    fragment AnnouncementFragment on Announcement {
      id
      priority
      text
      bots
      enabled
      runCondition
      publicPeriod
      periodStart
      periodEnd
      options
      postContext
      afterActionId
    }
  `

export default class AnnouncementStorage {
	
	constructor(client) {
		this.client = client;
		this.AnnouncementFragment = AnnouncementFragment;
	}
	
	pickOriginalError(path, error) {
		let originalError = Array.isArray(error.graphQLErrors)
			? error.graphQLErrors.find((error) => error.path[0] === path)
			: undefined;
		return Promise.reject(originalError || error);
	}
	
	fileRemove(infId, fileId) {
		let variables = {
			infId, fileId
		};
		const mutation = gql`
			mutation remove($infId: String, $fileId: String) {
				announceFileRemove(infId: $infId, fileId: $fileId) 
			}`;
		return this.client.mutate({mutation, variables});
	}
	
	fileApply(infId, fileId) {
		let variables = {
			infId, fileId
		};
		const mutation = gql`
			mutation apply($infId: String, $fileId: String) {
				announceFileApply(infId: $infId, fileId: $fileId)
			}`;
		return this.client.mutate({mutation, variables});
	}
	
	allAnnouncesReset(infId) {
		let variables = {
			infId
		};
		const mutation = gql`
			mutation reset($infId: String) {
				allAnnouncesReset(infId: $infId)
			}`;
		return this.client.mutate({mutation, variables});
	}


  upsertAnnouncement(announcement) {
		let variables = {
      announcement
		};
		const mutation = gql`
			mutation update($announcement: Json) {
        upsertAnnouncement(announcement: $announcement) {
          id
          priority
          text
          bots
          enabled
          runCondition
          publicPeriod
          periodStart
          periodEnd
          options
          postContext
          afterActionId
				}
			}`;
		return this.client.mutate({mutation, variables})
			.catch(this.pickOriginalError.bind(this, 'upsertAnnouncement'));
	}

  enableAnnouncement({ ids, enabled }) {
		let variables = {
      ids, enabled
		};
		const mutation = gql`
			mutation update($ids: [Int!]!, $enabled: Boolean) {
        enableAnnouncements(ids: $ids, enabled: $enabled) {
          id
          priority
          text
          bots
          enabled
          runCondition
          publicPeriod
          periodStart
          periodEnd
          options
          postContext
          afterActionId
				}
			}`;
		return this.client.mutate({mutation, variables})
			.catch(this.pickOriginalError.bind(this, 'enableAnnouncement'));
	}
	
	deleteAnnouncement({ ids }) {
		let variables = {
      ids
		};
		const mutation = gql`
			mutation delete($ids: [Int!]!) {
        deleteAnnouncements(ids: $ids) {
          id
          priority
          text
          bots
          enabled
          runCondition
          publicPeriod
          periodStart
          periodEnd
          options
          postContext
          afterActionId
				}
			}`;
		return this.client.mutate({mutation, variables})
			.catch(this.pickOriginalError.bind(this, 'deleteAnnouncements'));
	}

  applyAnnouncements({ accountId }) {
		let variables = {
      accountId
		};
		const mutation = gql`
			mutation apply($accountId: Int!) {
        applyAnnouncements(accountId: $accountId) 
			}`;
		return this.client.mutate({mutation, variables})
			.catch(this.pickOriginalError.bind(this, 'applyAnnouncements'));
	}
	
	
}