import gql from 'graphql-tag';


export default class NeuroClassifier {
  
  constructor(client, wsClient) {
    this.client = client;
    this.wsClient = wsClient;
  }
  
  pickOriginalError(path, error) {
    let originalError = Array.isArray(error.graphQLErrors)
      ? error.graphQLErrors.find((error) => error.path[0] === path)
      : undefined;
    return Promise.reject(originalError || error);
  }

  deleteRubric({ accountId, infId, msgId }) {
    let variables = {
      accountId, infId, msgId
    };
    console.log(variables)
    const mutation = gql`
        mutation deleteRubric($accountId: Int!, $infId: String!, $msgId: Int!) {
            neuroClassifierDeleteRubric(accountId: $accountId, infId: $infId, msgId: $msgId) {
                id
                ts
                context
                request
                response
                rate
                type
                nps
                engine_rubric
                confirmed_rubric
            }
        }`;
    return this.client.mutate({mutation, variables})
      .then((data) => {
        console.log('deleteRubric result', data)
        //store.dispatch(userUpdated(data))
        return data;
      })
      .catch(this.pickOriginalError.bind(this, 'deleteRubric'));
  }


  setRubric({ accountId, infId, msgId, msgTimestamp, engineRubricName, confirmedRubricName }) {
    let variables = {
      accountId, infId, msgId, msgTimestamp, engineRubricName, confirmedRubricName
    };
    const mutation = gql`
        mutation setRubric($accountId: Int!, $infId: String!, $msgId: Int!, $msgTimestamp: Date!, $engineRubricName: String, $confirmedRubricName: String!) {
            neuroClassifierSetRubric(
                accountId: $accountId, 
                infId: $infId, 
                msgId: $msgId, 
                msgTimestamp: $msgTimestamp, 
                engineRubricName:$engineRubricName, 
                confirmedRubricName: $confirmedRubricName) 
            {
                id
                ts
                context
                request
                response
                rate
                type
                nps
                engine_rubric
                confirmed_rubric
            }
        }`;
    return this.client.mutate({mutation, variables})
      .then((data) => {
        console.log('setRubric result', data)
        //store.dispatch(userUpdated(data))
        return data;
      })
      .catch(this.pickOriginalError.bind(this, 'setRubric'));
  }
  
}