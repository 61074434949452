import gql from 'graphql-tag';
import _ from 'lodash';
import {DASHBOARD_RESET_WIDGETS} from '../../routes/dashboard/DashboardActions';

export default class ConfigStorage {
  
  constructor(client, wsClient) {
    this.client = client;
    this.wsClient = wsClient;
    
    //список всех доступных чат-ботов
    //используется чтобы получать по infId название чат-бота
    this.infs = {};
    this.infpersons = {};
  }
  
  pickOriginalError(path, error) {
    let originalError = Array.isArray(error.graphQLErrors)
      ? error.graphQLErrors.find((error) => error.path[0] === path)
      : undefined;
    return Promise.reject(originalError || error);
  }
  
  get() {
    let data = localStorage.getItem('biz-main-filter');
    let accountId = null
    if (data) {
      data = JSON.parse(data);
      accountId = data.accountId || null;
    }
    const query = gql`
        query ($accountId: Int) {
            config {
                sessionId
                sessionUser {
                    id                   
                    name
                    photo
                    email
                    scope
                }
                bizacUser {
                  admin
                  options
                  disabled
                }
                accounts {
                    id
                    name
                    infs {
                        uuid
                        name
                        context
                        options
                    }
                    options {
                      accessFlags
                      announcements
                    }
                }
                reports
                widgets {
                    available {
                        id
                        name
                        description
                        type
                        indicatorIds
                        reportIds
                        tableIds
                    }
                    defaultMiniWidgets
                    defaultMaxiWidgets
                    shownMiniWidgetsIds
                    shownMaxiWidgetsIds
                }
                navigation {
                    pages { path action caption icon accessFlags }
                    actions { path action caption icon accessFlags }
                }
                defaultTimeFormat
                fulltextSearch
                env
                debug
                features(accountId: $accountId)
            }
        }
    `;
    return this.client.query({query, variables: { accountId }})
      .then((result) => {
        if (!result.data || !result.data.config) {
          return {};
        }
        
        //подготовим на основе конфига список всех доступных чат-ботов, с которым удобно будет работать
        
        let {accounts} = result.data.config;
        if(Array.isArray(accounts) ) {
          accounts.forEach(account => {
            if(!Array.isArray(account.infs)) return;
            account.infs.forEach(inf => {
              this.infs[inf.uuid] = inf.name;
              this.infpersons[inf.uuid] = (inf.context || {}).inf_person;
            });
          });
        }
        result.data.config.featureIsEnabled = function (id) {
          return result.data.config.features.hasOwnProperty(id) && result.data.config.features[id]
        }
        
        this.subscribe(result.data.config.sessionId);
        
        return {...result.data.config, storage: this };
      })
  }
  
  updateConfig(newValues) {
    let variables = {
      newValues
    };
    const mutation = gql`
        mutation update($newValues: UpdateConfigInput) {
            updateConfig(newValues: $newValues) {
                sessionId
            }
        }`;
    return this.client.mutate({mutation, variables})
      .then((user) => {
        //store.dispatch(userUpdated(user))
        return user;
      })
      .catch(this.pickOriginalError.bind(this, 'updateConfig'));
  }

  getFeatures({ accountId }) {
    let variables = { accountId  }
    const query = gql`
      query fetchFeatures(
        $accountId: Int!
      ) {
        features(
            accountId: $accountId
        )
      }
    `;
    return this.client.query({query, variables})
      .then((result) => {
        if (!result.data || !result.data.features) {
          return null;
        }
        const features = result.data.features;
        return {
          ...features,
          featureIsEnabled: function (id) {
            return features.hasOwnProperty(id) && features[id]
          }
        }
      })
  }
  
  subscribe(sessionId) {
    const sub = () => {
      this.client.subscribe({
        query: gql`
          subscription event($sessionId: String!) {
            sessionClosed(sessionId: $sessionId) {
              userId
              sessionId
            }
          }`,
        variables: {
          sessionId
        }
      }).subscribe({
        next(payload) {
          if (payload.data && payload.data.sessionClosed) {
            window.location.assign('/');
          }
        }
      });
    };
    this.wsClient.on('reconnected', sub);
    sub();
  }
  
  /*
      Сохраняет опции на сервере по изменению состояния react-redux.
      Список сохраняемых опций на сервер очень ограничен.
      Сохраняем только те опции, что являются персистентными и
      не должны зависеть от LocalStorage браузера или конкретной пользовательской сессии.
   */
  
  dumpConfigToserver = store => next => action => {
    const result = next(action);
    let newValues = {}, state;
    if (['DASHBOARD_ADD_WIDGET', 'DASHBOARD_REMOVE_WIDGET', 'DASHBOARD_SORT_WIDGETS', 'DASHBOARD_RESET_WIDGETS'].indexOf(action.type) !== -1) {
      state || (state = store.getState());
      if (action.widgetType === 'mini' || action.widgetType === 'all') {
        newValues.shownMiniWidgetsIds = state.dashboard.shownMiniWidgetsIds;
      }
      if (action.widgetType === 'maxi' || action.widgetType === 'all') {
        newValues.shownMaxiWidgetsIds = state.dashboard.shownMaxiWidgetsIds;
      }
    }
    
    if (_.size(newValues)) {
      this.updateConfig(newValues);
    }
    
    return result
  };
  
}