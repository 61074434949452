import gql from 'graphql-tag';

export default class UserStorage {
  
  constructor(client) {
    this.client = client;
  }
  
  pickOriginalError(path, error) {
    let originalError = Array.isArray(error.graphQLErrors)
      ? error.graphQLErrors.find((error) => error.path[0] === path)
      : undefined;
    return Promise.reject(originalError || error);
  }
  
  /**
   * 
   * @param {Object} userFields
   * @param {String} userFields.email
   * @param {String} userFields.firstName
   * @param {String} userFields.lastName
   * @param {String} userFields.newPassword
   * @param {Number} user_id
   * @returns {Promise<T>}
   */
  
  updateUser(userFields, user_id) {
    let variables = {
      userFields 
    };
    const mutation = gql`
      mutation update($userFields: UserFieldsInput) {
        updateUser(userFields: $userFields) {
          id
          name
          photo
          email
        }
      }`;
    return this.client.mutate({mutation, variables})
      .then((user) => {
        return user;
      })
      .catch(this.pickOriginalError.bind(this, 'userUpdate'));
  }
  
  
}