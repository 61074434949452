import gql from 'graphql-tag';
//import {userUpdated} from '../../components/common/Layout/UserActions';
//import {store} from '../configureStore';

export default class ChannelStorage {
	
	constructor(client) {
		this.client = client;
	}
	
	pickOriginalError(path, error) {
		let originalError = Array.isArray(error.graphQLErrors)
			? error.graphQLErrors.find((error) => error.path[0] === path)
			: undefined;
		return Promise.reject(originalError || error);
	}
	
	updateChannel(id, channel) {
		let variables = {
      id, channel
		};
		const mutation = gql`
			mutation update($id: String!, $channel: ChannelInput) {
        channelUpdate(id: $id, channel: $channel) {
          id
          infName
          infUuid
          token
          channelType
          linkShortener {
            token
            type
            utm
            cache
          }
          enabled
          botDisabledText
          webhook
          clientId
          clientSecret
        }
			}`;
		return this.client.mutate({mutation, variables})     
	}

  enableChannel(infId, enabled) {
		let variables = {
      infId, enabled
		};
		const mutation = gql`
			mutation update($infId: String!, $enabled: Boolean) {
        enableChannel(infId: $infId, enabled: $enabled)
			}`;
		return this.client.mutate({mutation, variables})     
	}
}