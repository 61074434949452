import gql from 'graphql-tag';

export default class ChatsStorage {

  constructor(client) {
    this.client = client;
  }

  pickOriginalError(path, error) {
    let originalError = Array.isArray(error.graphQLErrors)
      ? error.graphQLErrors.find((error) => error.path[0] === path)
      : undefined;
    return Promise.reject(originalError || error);
  }

  /**
   *
   * @param infId
   * @param timePeriod
   * @param advancedFilter
   * @returns boolean
   */

  exportChats({infId, timePeriod, advancedFilter, chatIds, accountId, exportView}) {
    accountId = accountId * 1;
    let variables = {
      infIds: [infId], timePeriod, advancedFilter, chatIds, exportView, accountId
    };
    const mutation = gql`
      mutation export($infIds: [String], $timePeriod:     TimePeriodInput, $advancedFilter: ChatsFilterInput, $chatIds: [Int], $exportView: ExportView, $accountId: Int) {
        exportChats(infIds: $infIds, timePeriod: $timePeriod, advancedFilter: $advancedFilter, chatIds: $chatIds, exportView: $exportView, accountId: $accountId)
      }`;
    return this.client.mutate({mutation, variables})
      .catch(this.pickOriginalError.bind(this, 'exportChats'));
  }


}