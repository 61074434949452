import gql from 'graphql-tag';
//import {userUpdated} from '../../components/common/Layout/UserActions';
//import {store} from '../configureStore';

export default class NotificationStorage {
	
	constructor(client) {
		this.client = client;
	}
	
	pickOriginalError(path, error) {
		let originalError = Array.isArray(error.graphQLErrors)
			? error.graphQLErrors.find((error) => error.path[0] === path)
			: undefined;
		return Promise.reject(originalError || error);
	}
	
	fileRemove(infId, fileId) {
		let variables = {
			infId, fileId
		};
		const mutation = gql`
			mutation remove($infId: String, $fileId: String) {
				notificationFileRemove(infId: $infId, fileId: $fileId) 
			}`;
		return this.client.mutate({mutation, variables});
	}
	
	fileApply(infId, fileId) {
		let variables = {
			infId, fileId
		};
		const mutation = gql`
			mutation apply($infId: String, $fileId: String) {
				notificationFileApply(infId: $infId, fileId: $fileId)
			}`;
		return this.client.mutate({mutation, variables});
	}
	
	allNotificationsReset(infId) {
		let variables = {
			infId
		};
		const mutation = gql`
			mutation reset($infId: String) {
				allNotificationsReset(infId: $infId)
			}`;
		return this.client.mutate({mutation, variables});
	}
	
	
	
}