/**
 * Autor Eugene Demchenko <demchenkoev@gmail.com>
 * Created on 04.12.17.
 * License BSD
 */

import React from 'react';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';


import ConfigStorage from './ConfigStorage';
import UserStorage from './UserStorage';
import AnnouncementStorage from './AnnouncementStorage';
import NotificationStorage from './NotificationStorage';
import ChatsStorage from './ChatsStorage';
import AnalyticsService from './AnalyticsService';
import ChannelStorage from './ChannelsStorage';
import NeuroClassifier from './NeuroClassifier';

import {SubscriptionClient, addGraphQLSubscriptions} from 'subscriptions-transport-ws';

export let traceIds = {};

class TraceIds extends React.Component {

    render() {
        let msg = [];
        for(let k in traceIds) {
            msg.push(`${k}=${traceIds[k]}`);
        }

        return (<div ref="trace">
            {msg.map((msg, index) => (<div key={index}>{msg}</div>) )}

            {/*<div className="notification-action-wrapper" style={{margin: '0px', padding: '0px'}}>
                <button className="notification-action-button" style={{
                    background: 'rgb(236, 61, 61)',
                    borderRadius: '2px',
                    padding: '6px 20px',
                    fontWeight: 'bold',
                    margin: '10px 0px 0px',
                    border: '0px',
                    color: 'rgb(255, 255, 255)'
                }}
                >
                    Скопировать в буфер обмена
                </button>
            </div>*/}

        </div>);
    }
}

export function traceIdNotification() {

    return {
        title: 'Список TraceID',
        children: (<TraceIds />),
        position: 'tc',
        autoDismiss: 0,
        dismissible: 'button',
    }
}

/*
// Create regular NetworkInterface by using apollo-client's API:
const networkInterface = createNetworkInterface({
  uri: `//${window.location.host}/graphql`
});
*/

// Create WebSocket client
const wsProtocol = window.location.protocol === 'https:' ? 'wss':'ws';
const wsClient = new SubscriptionClient(`${wsProtocol}://${window.location.host}/graphql`, {
  reconnect: true,
  connectionParams: {
    // Pass any arguments you want for initialization
  }
});

const link = new WebSocketLink(wsClient);
/*
// Extend the network interface with the WebSocket
const networkInterfaceWithSubscriptions = addGraphQLSubscriptions(
  networkInterface,
  wsClient
);
*/
const client = new ApolloClient({
  // By default, this client will send queries to the
  //  `/graphql` endpoint on the same host
  link/*: new HttpLink({
    credentials: 'include'
  })*/,
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

let configStorage = new ConfigStorage(client, wsClient);
let neuroClassifier = new NeuroClassifier(client, wsClient);
let userStorage = new UserStorage(client);
let announcementStorage = new AnnouncementStorage(client);
let notificationStorage = new NotificationStorage(client);
let chatsStorage = new ChatsStorage(client);
let analyticsService = new AnalyticsService(client);
let channelStorage = new ChannelStorage(client);


export {
  client, configStorage, userStorage, announcementStorage, notificationStorage, chatsStorage, 
  analyticsService, channelStorage, neuroClassifier}